import React, { Dispatch, ReactNode, SetStateAction } from "react";
import clsx from "clsx";

interface CardProps {
  children: ReactNode;
  fullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  hidden: boolean;
  highSchool: boolean;
}

type CardType =
  | "student"
  | "teacher";

function CardComponent(cardType: CardType) {
  const isTeacher: boolean = cardType === "teacher";

  function Card({ children, highSchool, fullScreen, hidden, setFullScreen }: CardProps) {
    function handleClick() {
      setFullScreen(!fullScreen);
    }

    return (
      <div
        className={clsx(
          "shadow-xl rounded mt-5 self-start bg-white", {
          "lg:ml-2": isTeacher && !fullScreen,
          "w-full": fullScreen,
          "lg:w-1/2": !fullScreen && !hidden,
          "hidden": hidden
        })}>
      	<div
        	className={clsx(
          	"flex text-gray-600 items-center rounded-t",
          	{
              "bg-purple-100": !isTeacher && !highSchool,
              "bg-purple-200": isTeacher && !highSchool,
              "bg-indigo-100": !isTeacher && highSchool,
              "bg-indigo-300": isTeacher && highSchool,
          	}
        	)}
      	>
          <h2 className="my-0 pl-4">{isTeacher ? "Teacher content" : "Student content"}</h2>
          <div className="ml-auto p-3 cursor-pointer" onClick={handleClick}>
            <i
              className={clsx({
                "fas fa-compress-wide": fullScreen,
                "fas fa-expand-wide": !fullScreen,

              })}
            />
          </div>
        </div>
        <div
          className={clsx(
            "px-5 py-3 rounded-b bg-white",
            {
              "lg:max-w-xl": !fullScreen
            }
          )}
        >
          {children}
        </div>
      </div>
    )
  }

  return Card;
}

const StudentCard = CardComponent("student");
const TeacherCard = CardComponent("teacher");

export {
  StudentCard,
  TeacherCard
};
