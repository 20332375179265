import { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { MainContext } from "~/context";


function useAuth(path: string) {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const { user, loading } = useContext(MainContext);

  async function authNav(path: string) {
    setAuthenticated(true);
    const windowExists: boolean = typeof window !== "undefined";

    if (!user) {
      if (windowExists) {
        localStorage.setItem("requestedPage", window.location.pathname);
      }
      navigate(path);
      return;
    }

    if (windowExists) {
      localStorage.removeItem("requestedPage");
    }
  }

  useEffect(() => {
    authNav(path);
  }, [loading]);

  return authenticated;
}

export default useAuth;
