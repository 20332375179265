import React, { ReactNode } from "react";

import useAuth from "~/hooks/useAuth";

const TeacherViewWrapper = ({ children }: { children: ReactNode }) => {
  const authenticated: boolean = useAuth("/sign-in");

  if (!authenticated) {
    return (<div>...loading</div>);
  }

  return (
    <div className="flex flex-wrap lg:flex-nowrap pr-6">
      {children}
    </div>
  )
};

export default TeacherViewWrapper;
